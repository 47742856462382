import React, { useEffect } from 'react'

const MarketStatusIndicator = () => {
  useEffect(() => {
    let intervalId = setInterval(() => {
      let currentTime = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/New_York',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }).format()
      document.getElementById('marketStatus').innerText = `${currentTime} EST`
    }, 1000)

    return () => clearInterval(intervalId)
  }, [])

  return <div className="market-status" id="marketStatus"></div>
}

export default React.memo(MarketStatusIndicator)
