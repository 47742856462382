import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import './scss/style.scss'
import 'sweetalert2/src/sweetalert2.scss'
import { AppSidebar, AppLoader } from './components/index'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Home = React.lazy(() => import('./views/pages/home/Home'))
// const Login = React.lazy(() => import('./views/pages/login/Login'))
const Login = React.lazy(() => import('./views/pages/authentication/login'))
const ForgotPassword = React.lazy(() => import('./views/pages/authentication/forget-password'))
const Register = React.lazy(() => import('./views/pages/authentication/register'))

// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'))
// const Product = React.lazy(() => import('./views/pages/product/Product'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
class App extends Component {

  componentDidMount() {
    this.cleanUpAnonymousLogin()

    window.addEventListener('storage', (event) => {
      if (event.key == 'token') {
        if (!event.newValue) {
          window.location.href = '/'
        }
      }
    })

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registration successful with scope: ', registration.scope)
        }).catch((err) => {
          console.log('Service Worker registration failed: ', err)
        })
    }
  }

  cleanUpAnonymousLogin() {
    let user = localStorage.getItem('user')
    if (user) {
      user = JSON.parse(user)
      if (user.anonymous) {
        localStorage.clear()
      }
    }
  }

  render() {
    return (
      <HashRouter>
        <AppSidebar />
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/" element={<Login />} />
            {/* <Route exact path="/home" name="Home Page" element={<Home />} /> */}
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/forgotPassword" name="Forgot Password Page" element={<ForgotPassword />} />
            {/* <Route exact path="/products" name="Product Page" element={<Product />} /> */}
            {/* <Route exact path="/404" name="Page 404" element={<Page404 />} /> */}
            {/* <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
        <AppLoader />
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          draggable={false}
          pauseOnHover={false}
          theme="dark"
          limit={1}
        />
      </HashRouter>
    )
  }
}

export default App
