import React, { Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate, useLocation, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { CContainer, CSpinner } from '@coreui/react'

// routes config
import routes from '../routes'
import axios from '../axios'

//
// import React from 'react'
// import {useSelector} from "react-redux"
// import {Navigate, useLocation} from "react-router-dom"

// const ProtectedRoute = ({children}) => {
//     const user = useSelector((state) => state.user);
//     let location = useLocation();

//     if(!user.state.isAuthenticated) {
//         return <Navigate to="/login" state={{ from: location}} replace />
//     }
//  return children

// };

// export default ProtectedRoute;


// Use
{/* <Route path="/" element={
  <ProtectedRoute>
    <Home />
  </ProtectedRoute>
} /> */}

const AppContent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  let user = useSelector((state) => state.user)

  useEffect(() => {
    getProfile()
  }, [])

  const getProfile = async () => {
    if (localStorage.getItem('token')) {
      if (!user) {
        let res = await axios.get('/auth/profile')
        if (res) {
          if (res.data.status) {
            let user = res.data.data
            dispatch({ type: 'login', user: user })
          } else {
            validateAnonymousAccess()
          }
        }
      }
    } else {
      validateAnonymousAccess()
    }
  }

  const validateAnonymousAccess = async () => {
    if (location.pathname.startsWith('/dashboard/')) {
      let cid = location.pathname.replace('/dashboard/', '')
      axios.get(`/public/channel/${cid}`)
        .then(res => {
          let channel = res.data.data
          if (channel.free) {
            dispatch({ type: 'set', anonymousLogin: true })
          } else {
            navigate('/', { replace: true })
          }
        })
        .catch(err => {
          navigate('/', { replace: true })
        })
    } else {
      navigate('/', { replace: true })
    }
  }

  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            )
          })}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes>
      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
