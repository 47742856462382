import React from 'react'
import { useSelector } from 'react-redux'
import Loading from 'react-fullscreen-loading'

const AppLoader = () => {
  const loader = useSelector((state) => state.loader)
  return (
    <>
      {loader && (
        <Loading loading={loader} background="#00000080" loaderColor="#357699" />
      )}
    </>
  )
}

export default AppLoader
