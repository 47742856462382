import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilDrop
} from '@coreui/icons'
import {  CNavItem, CNavTitle } from '@coreui/react'

export const adminNavigation = [
  {
    component: CNavTitle,
    name: 'MENU',
    is_sidebar:"true"
  },
  {
    component: CNavItem,
    name: 'Admin',
    to: '/admin/finance',
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Alert History',
    to: '/admin/alerts',
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Profile',
    to: '/admin/profile',
    is_dropdown : "true",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  },
]

export const userNavigation = [
  {
    component: CNavTitle,
    name: 'MENU',
    is_sidebar:"true"
  },
  {
    component: CNavItem,
    name: 'Products',
    to: '/products',
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Profile',
    to: '/profile',
    is_dropdown : "true",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Support',
    to: '/support',
    is_dropdown : "true",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  }
]

