import firebase from "firebase/compat/app"
import 'firebase/compat/auth'
import 'firebase/compat/messaging'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBG4QzV1tRby6kTbDI2WSLRN9TAOfuAeec",
  authDomain: "picco-trading.firebaseapp.com",
  projectId: "picco-trading",
  storageBucket: "picco-trading.appspot.com",
  messagingSenderId: "189945916868",
  appId: "1:189945916868:web:c637bc57e4f022bcb88891"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const messaging = firebase.messaging()

messaging.onMessage(payload => {
  console.log('Message received. ', payload)
  if (payload.data) {
    const notificationTitle = payload.data.title
    const notificationOptions = {
      icon: payload.data.icon,
      body: payload.data.body,
      data: {
        click_action: self.location.origin + payload.data.click_action
      }
    }
    if (Notification.permission === 'granted') {
      new Notification(notificationTitle, notificationOptions)
    }
  }
})

// messaging.getToken().catch(err => { console.log(err) })

// navigator.serviceWorker.onmessage = event => {
//   if (event.data.messageType === 'notification-clicked') {
//     window.location.href = event.data.notification.click_action;
//   }
// };

export { messaging }
