import React, { useState } from 'react';
import { CButton, CModal, CModalHeader, CModalTitle, CRow, CCol, CModalFooter, CModalBody, CNavLink } from '@coreui/react';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const doLogout = () => {
    setShowLogoutModal(false)
    dispatch({ type: 'logout' })
    navigate('/', { replace: true })
  }

  return (
    <>

      <CNavLink className='dropdown-logout' onClick={() => setShowLogoutModal(true)}>
        <div className="dropdown-option" >
          Sign Out
        </div>
      </CNavLink>
      <CModal
        className="modal-custom modal-custom-logout"
        scrollable
        alignment="center"
        size="lg"
        visible={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        aria-labelledby="ScrollingLongContentExampleLabel2">

        <CModalBody>
          <CRow>
            <CCol className="mb-4 logout-content">
              <h3>Sign Out</h3>
              <span>Are you sure you want to Sign Out?</span>
            </CCol>
          </CRow>
          <CRow>
            <CCol className="btns-container">
              <CButton className="cancel-btn" onClick={() => setShowLogoutModal(false)}>
                Cancel
              </CButton>
              <CButton onClick={doLogout} className="logout-btn">
                Sign Out
              </CButton>
            </CCol>
          </CRow>
        </CModalBody>
      </CModal>

    </>
  );
};

export default LogoutButton;
