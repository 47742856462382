import axios from "axios"
import { messaging } from 'src/firebase'

const axiosClient = axios.create({
  // baseURL: "http://localhost:3005",
  baseURL: process.env.REACT_APP_API_URL
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    config.headers.token = token || ''
    return config;
  }
)

axiosClient.interceptors.response.use(
  (response) => {
    if (response.data && response.data.code && response.data.code == 401) {
      localStorage.clear()
      messaging.deleteToken().catch(_ => { })
      window.location.href = window.location.origin
    }
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default axiosClient