import React, { useEffect, useState } from 'react'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'

import { AppSidebarNav } from './nav'
import BrandLogo from 'src/assets/logo-1.png'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { adminNavigation, userNavigation } from '../../_nav'
import LogoutButton from './logoutButton';
import { useSelector, useDispatch } from 'react-redux'
import './style.css';
import { CNavItem } from '@coreui/react'
import axios from '../../axios'
import { useNavigate } from 'react-router-dom'

const AppSidebar = () => {

  const user = useSelector((state) => state.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  const unreadCount = useSelector((state) => state.unreadCount)
  const [navigationItems, setNavigationItems] = useState([]);
  const [userChannelsList, setUserChannelsList] = useState([]);

  useEffect(() => {
    if (user) {
      fetchChannels();
    }
  }, [user])

  useEffect(() => {
    if (unreadCount) {
      setNavigationItems(prev => {
        prev = prev.map(item => {
          if (item._id) {
            let refData = unreadCount.find(ch => ch._id == item._id)
            if (refData) {
              item['badge'] = {
                text: refData.count
              }
            }
          }
          return item
        })
        return prev
      })
    }
  }, [unreadCount])

  const fetchChannels = (url) => {
    axios.get('/channel/channels-list')
      .then(res => {
        if (res.data.status) {
          let navigationItems = [];
          let channels = res?.data?.data;
          setUserChannelsList(channels)

          if (user?.role?.type == 'SUPER_ADMIN' || user?.role?.type == 'ADMIN') {
            for (let index = 0; index < channels.length; index++) {
              navigationItems.push({
                component: CNavItem,
                name: channels[index].name,
                to: '/admin/dashboard/' + channels[index]._id,
              })
            }
          } else {
            for (let index = 0; index < channels.length; index++) {
              if (unreadCount === undefined) {
                navigationItems.push({
                  component: CNavItem,
                  name: channels[index].name,
                  // badge : {text : unreadCount[index1].count},
                  to: '/dashboard/' + channels[index]._id,
                })
              } else {
                for (let index1 = 0; index1 < unreadCount.length; index1++) {
                  if (channels[index]._id === unreadCount[index1]._id) {
                    navigationItems.push({
                      component: CNavItem,
                      name: channels[index].name,
                      badge: { text: unreadCount[index1].count },
                      to: '/dashboard/' + channels[index]._id
                    })
                  }
                }
              }
            }
          }

          if (user?.role?.type == 'SUPER_ADMIN' || user?.role?.type == 'ADMIN') {
            navigationItems = [...navigationItems, ...adminNavigation];
          } else {
            navigationItems = [...navigationItems, ...userNavigation];
          }

          setNavigationItems(navigationItems)

        } else {
          // console.error(res.data.message);
        }
      })
      .catch(err => {
        console.error('Error fetching channels:', err);
      });
  };

  const navigateToHome = () => {
    for (let index = 0; index < userChannelsList.length; index++) {

      if (user?.role?.type == 'SUPER_ADMIN') {
        navigate("/admin/dashboard/" + userChannelsList[index]._id, { replace: true });
        break;
      } else {

        if (userChannelsList[index].subscribed) {
          navigate("/dashboard/" + userChannelsList[index]._id, { replace: true });
          break;
        }
      }
    }

    if (user?.role?.type == 'SUPER_ADMIN')
      navigate("/admin/dashboard/" + userChannelsList[0]._id, { replace: true });
    else
      navigate("/dashboard/" + userChannelsList[0]._id, { replace: true });

  }

  if (user === undefined || user === null)
    return null;
  else
    return (
      <CSidebar
        className="sidebar_custom"
        position="fixed"
        unfoldable={unfoldable}
        visible={sidebarShow}
        onVisibleChange={(visible) => {
          dispatch({ type: 'set', sidebarShow: visible })
        }}
      >
        <CSidebarBrand className="d-none d-md-flex" to="/">
          <img className="logo-img" onClick={() => navigateToHome()} src={BrandLogo} height={30} />
        </CSidebarBrand>
        <CSidebarNav>
          <SimpleBar>
            {/* <AppSidebarNav items={user?.role?.type == 'SUPER_ADMIN' ? adminNavigation : userNavigation} /> */}
            <AppSidebarNav items={navigationItems} />
          </SimpleBar>
        </CSidebarNav>
        {/* <CSidebarToggler
                className="d-none d-lg-flex"
                onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
            /> */}
        <LogoutButton />
      </CSidebar>
    )
}

export default React.memo(AppSidebar)
