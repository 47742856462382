import React, { useState } from 'react';
import { CButton, CModal, CModalHeader, CModalTitle, CRow, CCol, CModalFooter, CModalBody } from '@coreui/react';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const doLogout = () => {
    setShowLogoutModal(false)
    dispatch({ type: 'logout' })
    navigate('/', { replace: true })
  }

  return (
    <>
      <CButton onClick={() => setShowLogoutModal(true)} color="primary" className="logout-button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.1885 9C19.1755 9.74024 20.0668 10.599 20.8426 11.5564C20.9475 11.6859 21 11.843 21 12M18.1885 15C19.1755 14.2598 20.0668 13.401 20.8426 12.4436C20.9475 12.3141 21 12.157 21 12M21 12H8M13 4.52779C11.9385 3.57771 10.5367 3 9 3C5.68629 3 3 5.68629 3 9V15C3 18.3137 5.68629 21 9 21C10.5367 21 11.9385 20.4223 13 19.4722" stroke="#3477F1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        Sign Out
    </CButton>
      <CModal
        className="modal-custom modal-custom-logout"
        scrollable
        alignment="center"
        size="lg"
        visible={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        aria-labelledby="ScrollingLongContentExampleLabel2">

        <CModalBody>
          <CRow>
            <CCol className="mb-4 logout-content">
              <h3>Sign Out</h3>
              <span>Are you sure you want to Sign Out?</span>
            </CCol>
          </CRow>
          <CRow>
            <CCol className="btns-container">
              <CButton className="cancel-btn" onClick={() => setShowLogoutModal(false)}>
                Cancel
            </CButton>
              <CButton onClick={doLogout} className="logout-btn">
                Sign Out
            </CButton>
            </CCol>
          </CRow>
        </CModalBody>
      </CModal>

    </>
  );
};

export default LogoutButton;
