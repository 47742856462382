import React, { useEffect, useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  CContainer,
  CHeaderToggler,
  CHeader,
  CHeaderNav,
  CNavLink,
  CNavItem
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu, cilBell } from '@coreui/icons'
import './style.css';
import logo from 'src/assets/logo-1.png'
import UserLogo from 'src/assets/user-logo.png';
import axios from '../../axios'
import { adminNavigation, userNavigation } from '../../_nav'
import LogoutButton from './logoutButton';

import MarketStatusIndicator from './marketStatus';
const AppHeader = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const user = useSelector((state) => state.user)
  const notificationCount = useSelector((state) => state.notificationCount)

  const unreadCount = useSelector((state) => state.unreadCount)
  const [navigationItems, setNavigationItems] = useState([]);
  const [userChannelsList, setUserChannelsList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference to the dropdown

  useEffect(() => {
    if (user) {
      fetchChannels();
    }
  }, [user])

  useEffect(() => {
    if (unreadCount) {
      setNavigationItems(prev => {
        prev = prev.map(item => {
          if (item._id) {
            let refData = unreadCount.find(ch => ch._id == item._id)
            if (refData) {
              item['badge'] = {
                text: refData.count
              }
            }
          }
          return item
        })
        return prev
      })
    }
  }, [unreadCount])

  const fetchChannels = (url) => {
    axios.get('/channel/channels-list')
      .then(res => {
        if (res.data.status) {
          let navigationItems = [];
          let channels = res?.data?.data;
          setUserChannelsList(channels)

          if (user?.role?.type == 'SUPER_ADMIN' || user?.role?.type == 'ADMIN') {
            for (let index = 0; index < channels.length; index++) {
              navigationItems.push({
                component: CNavItem,
                name: channels[index].name,
                to: '/admin/dashboard/' + channels[index]._id,
              })
            }
          } else {
            for (let index = 0; index < channels.length; index++) {
              if (unreadCount === undefined) {
                navigationItems.push({
                  component: CNavItem,
                  _id: channels[index]._id,
                  name: channels[index].name,
                  // badge : {text : unreadCount[index1].count},
                  to: '/dashboard/' + channels[index]._id,
                })
              } else {
                for (let index1 = 0; index1 < unreadCount.length; index1++) {
                  if (channels[index]._id === unreadCount[index1]._id) {
                    navigationItems.push({
                      component: CNavItem,
                      _id: channels[index]._id,
                      name: channels[index].name,
                      badge: { text: unreadCount[index1].count },
                      to: '/dashboard/' + channels[index]._id
                    })
                  }
                }
              }
            }
          }

          if (user?.role?.type == 'SUPER_ADMIN' || user?.role?.type == 'ADMIN') {
            navigationItems = [...navigationItems, ...adminNavigation];
          } else {
            navigationItems = [...navigationItems, ...userNavigation];
          }

          if (user.anonymous) {
            navigationItems = navigationItems.filter(item => item.name !== 'Profile')
          }

          setNavigationItems(navigationItems)

        } else {
          // console.error(res.data.message);
        }
      })
      .catch(err => {
        console.error('Error fetching channels:', err);
      });
  };

  const navigateToHome = () => {
    for (let index = 0; index < userChannelsList.length; index++) {

      if (user?.role?.type == 'SUPER_ADMIN') {
        navigate("/admin/dashboard/" + userChannelsList[index]._id, { replace: true });
        break;
      } else {

        if (userChannelsList[index].subscribed) {
          navigate("/dashboard/" + userChannelsList[index]._id, { replace: true });
          break;
        }
      }
    }

    if (user?.role?.type == 'SUPER_ADMIN')
      navigate("/admin/dashboard/" + userChannelsList[0]._id, { replace: true });
    else
      navigate("/dashboard/" + userChannelsList[0]._id, { replace: true });

  }

  const renderDropDownIcon = () => {
    return (
      <svg
        width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 7.5C6.31417 9.31733 7.85245 10.947 9.5749 12.3476C9.82473 12.5508 10.1753 12.5508 10.4251 12.3476C12.1476 10.947 13.6858 9.31733 15 7.5" stroke="#949494" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !document.querySelector('.modal')) {
        setIsOpen(false); // Close the dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, setIsOpen]);

  return (
    <CHeader position="sticky" className="custom_header">
      <CContainer fluid>
        <div className="d-flex logo-container">
          <CHeaderToggler
            className="ps-1 show-menu-toggle"
            onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
          >
            <CIcon icon={cilMenu} size="lg" style={{ color: "#fff" }} />
          </CHeaderToggler>
          <img onClick={() => navigateToHome()} src={logo} alt="logo" />
          <CHeaderNav className="d-md-flex">
            <CNavItem>
              <div className="app-logo">
                <span className="welcome_txt">Welcome{user?.name ? ',' : ''}</span>
                <span className="username_txt">{user?.name}</span>
              </div>
            </CNavItem>
          </CHeaderNav>
        </div>

        <CHeaderNav className="d-none d-md-flex hide-menu-toggle-items">
          {navigationItems.map((data, index) => {
            if (!data.is_dropdown && !data?.is_sidebar) {
              return (
                <CNavItem className="ms-3" key={index}>
                  <CNavLink to={data?.to} component={NavLink}>
                    {data?.name}
                    {data.badge && data?.badge?.text > 0 && <span className='badge-count'>{data?.badge?.text}</span>}
                  </CNavLink>
                </CNavItem>
              )
            }
          })}
        </CHeaderNav>

        <CHeaderNav className="d-none d-md-flex market-profile-container">
          <MarketStatusIndicator />

          <div className="custom-dropdown" ref={dropdownRef}>
            <div className="dropdown-selected" style={{ border: 0 }} onClick={() => setIsOpen(!isOpen)}>
              <img src={UserLogo} alt="logo" />
              {renderDropDownIcon()}
            </div>
            {isOpen && (
              <div className="dropdown-options">
                {navigationItems.map((data, index) => {
                  if (data?.is_dropdown) {
                    return (
                      <CNavLink key={index} onClick={() => setIsOpen(!isOpen)} to={data?.to} component={NavLink} style={{ color: "#5B5B5D", padding: 0 }}>
                        <div className="dropdown-option" >
                          {data?.name}
                        </div>
                      </CNavLink>
                    )
                  }
                })}
                <LogoutButton />
              </div>
            )}
          </div>
        </CHeaderNav>
      </CContainer>
    </CHeader >
  )
}

export default AppHeader